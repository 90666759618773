import { GET_CERTIFICATE } from './-getter-types';
import { useCertificatesStore } from '~/stores/certificates';
import type { Certificate } from '~/models/Certificate';

const Getters = {
    [GET_CERTIFICATE]: (): Certificate | null => {
        const certificatesStore = useCertificatesStore();

        return certificatesStore.certificate;
    },
};

export default Getters;
