/* eslint-disable vue/sort-keys */

import { defineStore } from 'pinia';
import { DefaultState } from './certificates/state';
import Getters from './certificates/getters';
import Actions from './certificates/actions';
import Mutations from './certificates/mutations';

export const useCertificatesStore = defineStore(
    'certificates',
    {
        state: DefaultState,
        getters: Getters,
        actions: {
            ...Actions,
            ...Mutations,
        },
    },
);
