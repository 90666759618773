import { SET_CERTIFICATE } from './-mutation-types';
import { useCertificatesStore } from '~/stores/certificates';
import type { Certificate } from '~/models/Certificate';

const Mutations = {
    [SET_CERTIFICATE](payload: Certificate | null) {
        const certificatesStore = useCertificatesStore();
        certificatesStore.certificate = payload;
    },
};

export default Mutations;
