import { SET_CERTIFICATE } from './-mutation-types';
import { useCertificatesStore } from '~/stores/certificates';
import CertificateService from '~/services/api/CertificateService';
import type { Certificate } from '~/models/Certificate';

const Actions = {
    async fetchCertificate(certificateId: number) {
        const certificatesStore = useCertificatesStore();

        const certificateService = new CertificateService();
        const response = await certificateService.fetchCertificate(certificateId);

        certificatesStore[SET_CERTIFICATE](response);
    },
    setCertificate(certificate: Certificate) {
        const certificatesStore = useCertificatesStore();
        certificatesStore[SET_CERTIFICATE](certificate);
    },
};

export default Actions;
